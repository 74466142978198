import React from "react"
import * as styles from "./footer-section.module.scss"
import {Container, Row, Col} from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import MainLogoSVG from "../../svg/beefteka_logo_white.svg";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";

export default function FooterSection(props) {
    return <>
        <span id="footer-section"></span>
        <div className={styles.wrapper}>

            <Container>
                <VerticalSpacingRow/>
                <Row className="justify-content-around" center="xs">
                    <Col md={{span: 2, order: 1}} xs={{span: 12, order: 1}}>
                        <div className={styles.logoWrapper}>
                            <MainLogoSVG/>
                        </div>
                    </Col>
                    <Col md={{span: 9, offset: 1, order: 2}} xs={{span: 12, order: 2}}>
                        <Row>
                            <Col
                                md={{span: 3, offset: 1}}
                            >
                                <div className={styles.columnHeader}>MAPA STRONY</div>

                                {props.menuList.map((item, index) =>
                                    <div
                                        className={`${styles.columnLink}`}
                                        onClick={() => item.onClick()}
                                        key={index}
                                    >
                                        <span>{item.text}</span>
                                    </div>
                                )}
                            </Col>
                            <Col md={4}>
                                <div className={styles.columnHeader}>GODZINY OTWARCIA</div>
                                <div className={styles.columnInfo}>
                                    Pon. - czw. 13:00 - 22:00
                                </div>
                                <div className={styles.columnInfo}>
                                    Pt. - sob. 13:00 - 23:00
                                </div>
                                <div className={styles.columnInfo}>
                                    Niedziela 13:00 - 21:00
                                </div>
                            </Col>

                            <Col
                                md={4}
                                // xs={{ span: 6 }}
                            >
                                <div className={styles.columnHeader}>KONTAKT</div>

                                <div className={styles.columnInfo}>
                                    Restauracja Beefteka
                                </div>
                                <div className={styles.columnLink}>
                                    <a href="https://goo.gl/maps/Qq7qVwT6wXh4cRQK9" target="_blank">
                                        ul. Henryka Sienkiewicza 35
                                        <br/>
                                        62-600 Koło
                                    </a>
                                </div>
                                <div className={styles.columnLink}>
                                    <a href="mailto:kontakt@browarkolo.pl">kontakt@beefteka.pl</a>
                                </div>
                                <div className={styles.columnLink}>
                                    <a href="tel:+48 535 76 76 76">+48 535 76 76 76</a>
                                </div>
                                <div className={styles.socialIconsRow}>
                                    <a href="https://www.facebook.com/Beefteka" aria-label="social media link">
                                        <span className={"icon-social-facebook-icon"}></span>
                                    </a>
                                    <a href="https://www.instagram.com/beefteka/"
                                       aria-label="social media link">
                                        <span className={"icon-social-instagram-icon"}></span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <VerticalSpacingSmallRow/>
            </Container>
        </div>

    </>
}
